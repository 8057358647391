.maintenance-container {
  background-color: #1c1d26;
  color: #ffffff;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.maintenance-logo img {
  max-width: 240px;
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  object-fit: contain;
}

.maintenance-content {
  max-width: 700px;
  padding: 0 15px;
}

.maintenance-content h1 {
  font-size: var(--font-xl-title, 42px);
  font-weight: var(--font-weight-700, 700);
  color: var(--light-green-color, #b6ff4d);
  font-family: "Impact", sans-serif;
  margin-bottom: 16px;
}

.maintenance-content p {
  margin-bottom: 6px; /* Reduced from 12px */
  font-size: var(--font-large-content, 18px);
  font-family: "Inter", sans-serif;
  line-height: 1.4; /* Reduced from 1.6 */
}
.maintenance-content p:last-child {
  margin-bottom: 0;
}
.maintenance-content .info-link a {
  color: var(--light-green-color, #b6ff4d);
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.2s ease-in-out;
}

.maintenance-content .info-link a:hover {
  opacity: 0.75;
}

@media screen and (max-width: 480px) {
  .maintenance-content h1 {
    font-size: 28px;
  }

  .maintenance-content p {
    font-size: 16px;
  }

  .maintenance-logo img {
    max-width: 180px;
  }
}
