@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Impact:wght@400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
:root {
  --primary-color: #c3ff55; /* Define your primary color */
  --secondary-color: #6c757d; /* Define your secondary color */
  --background-color: #1c1d26; /* Define background dark color */
  --background-color-light: #262835; /* Define background light color */
  --border-color: #f8f9fa; /* Define background color */
  --light-green-color: #c3ff55;
  --white-color: #ffffff; /* Define text color */
  --accent-color: #ffc107; /* Define accent color */
  --success-color: #28a745; /* Define success color */
  --error-color: #dc3545; /* Define error color */
  --warning-color: #ffc107; /* Define warning color */
  --disabled-color:rgba(144, 156, 176, 1);
  --hover-second-button: rgba(56, 65, 74, 1);

  // font size
  --font-size-sm: 14px;
  --font-size-btn: 16px;
  --font-size-base: 16px;
  --font-main-title: 32px;
  --font-main-point-title: 40px;
  --font-sub-title: 24px;
  --font-large-content: 20px;
  --font-small-content: 16px;
  --font-xl-title: 60px;
  --font-xxl-title: 80px;

  // font weight
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  --font-weight-bold: bold;
}

* {
  margin: 0;
  padding: 0;
  // font-family: "Inter", sans-serif !important;
}

body {
  background-color: var(--background-color);
  color: var(--white-color);
  line-height: normal;
  font-family: "Inter", sans-serif !important;

}
.customSpinPrimaryButton{
  cursor: pointer;
  background-color: var(--light-green-color);
  border-radius: 50px;
  padding: 20px 60px 20px 60px;
  border: 0;
  margin-top: 25px;
  color: var(--background-color);
  font-weight: bold;
  font-size: var(--font-size-btn);
  &:hover {
    color: var(--background-color);
    background-color: #9ccc44;
  }
}
.customPrimaryButton {
  cursor: pointer;
  background-color: var(--light-green-color);
  border-radius: 50px;
  padding: 16px 24px 16px 24px;
  border: 0;
  color: var(--background-color);
  font-weight: bold;
  font-size: var(--font-size-btn);
  &:hover {
    color: var(--background-color);
    background-color: #9ccc44;
  }
  position: relative;
  z-index: 1;
}
.customSpinTimerButton{
  background-color: var(--hover-second-button);
  border-radius: 50px;
  padding: 16px 24px 16px 24px;
  border: 0;
  color: var(--white-color);
  font-weight: bold;
  font-size: var(--font-size-btn);
  &:hover {
    color: var(--white-color);
    background-color: var(
      --hover-second-button
    ); /* Adding box shadow for hover effect */
  }
}
.customSecondaryButton {
  cursor: pointer;
  background-color: var(--background-color-light);
  border-radius: 50px;
  padding: 16px 24px 16px 24px;
  border: 0;
  color: var(--white-color);
  font-weight: bold;
  font-size: var(--font-size-btn);
  &:hover {
    color: var(--white-color);
    background-color: var(
      --hover-second-button
    ); /* Adding box shadow for hover effect */
  }
}
.customCompletedButton {
  background-color: var(--hover-second-button);
  border-radius: 50px;
  padding: 16px 24px 16px 24px;
  border: 0;
  color: var(--white-color);
  font-weight: bold;
  font-size: var(--font-size-btn);
  &:hover {
    color: var(--white-color);
    background-color: var(
      --hover-second-button
    ); /* Adding box shadow for hover effect */
  }
}
.customProfileButton {
  cursor: pointer;
  padding: 16px 24px 16px 24px;
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid var(--white-color);
  color: var(--white-color);
  font-size: var(--font-size-btn);
  font-weight: 700;
  &:hover {
    color: var(--white-color);
    background-color: var(
      --secondary-color
    ); /* Adding box shadow for hover effect */
  }
}
.customWhiteButton {
  cursor: pointer;
  padding: 16px 24px 16px 24px;
  border-radius: 50px;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  color: var(--background-color);
  font-size: var(--font-size-btn);
  font-weight: 700;
}
.start-button-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontal-line {
  border-top: 1px solid #ffffff;
}

// home page get started button
.get-started-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

@media (min-width: 1300px) and (max-width: 1499px) {
  :root {
    // font size
    --font-main-title: 32px;
    --font-sub-title: 24px;
    --font-large-content: 20px;
    --font-small-content: 16px;
    --font-xl-title: 60px;
    --font-xxl-title: 70px;

  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  :root {
    // font size
    --font-sub-title: 24px;
    --font-small-content: 16px;
    --font-xl-title: 60px;
    --font-xxl-title: 70px;

  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  :root {
    // font size
    --font-sub-title: 24px;
    --font-small-content: 16px;
    --font-xl-title: 48px;
    --font-xxl-title: 70px;

  }
}

@media (min-width: 768px) and (max-width: 991px) {
  :root {
    // font size
    --font-main-title: 30px;
    --font-sub-title: 24px;
    --font-small-content: 16px;
    --font-xl-title: 48px;
    --font-xxl-title: 60px;

  }
}

@media (min-width: 576px) and (max-width: 767px) {
  :root {
    // font size
    --font-main-title: 30px;
    --font-sub-title: 24px;
    --font-small-content: 16px;
    --font-xl-title: 48px;
    --font-xxl-title: 60px;

  }
}

@media (min-width: 320px) and (max-width: 575px) {
  :root {
    // font size
    --font-main-title: 24px;
    --font-sub-title: 24px;
    --font-large-content: 20px;
    --font-small-content: 16px;
    --font-xl-title: 42px;
    --font-xxl-title: 50px;

  }
  .customProfileButton {
    padding: 10px 16px;
    font-size: var(--font-size-sm);
  }
}
