.continue-mission-button {
  justify-content: center;
  display: flex;
  margin: 25px 0px;
}
.bhdLno {
  max-width: 450px !important;
  max-height: 450px !important;
}
.customSpinPrimaryButton:disabled {
  background-color: #b7f74170;
  pointer-events: none;
}
@media (min-width: 1300px) and (max-width: 1499px) {
  .bhdLno {
    max-width: 400px !important;
    max-height: 400px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .bhdLno {
    max-width: 400px !important;
    max-height: 400px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bhdLno {
    max-width: 400px !important;
    max-height: 400px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bhdLno {
    max-width: 350px !important;
    max-height: 350px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .bhdLno {
    max-width: 300px !important;
    max-height: 300px !important;
  }
}


@media (min-width: 481px) and (max-width: 575px) {
  .bhdLno {
    max-width: 280px !important;
    max-height: 280px !important;
  }
}


@media (min-width: 320px) and (max-width: 480px) {
  .bhdLno {
    max-width: 180px !important;
    max-height: 180px !important;
  }
  .gTLZXx {
    top: -12px !important;
    left: 45% !important;
  }
}
