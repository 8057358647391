.profile-page {
  background: linear-gradient(0deg, #1c1d26 0%, rgba(28, 29, 38, 0) 100%);
  position: relative;
  z-index: 1;
}

.profile-container {
  margin-top: 120px;
  text-align: center;
  z-index: 1;
  position: relative;
  border-radius: 40px;
  border: 1px solid rgba(56, 65, 74, 1);
  background-color: var(--background-color);
  padding: 32px 110px;
}
.profile-container .daily-spin-section,
.profile-container .profile-detail {
  margin-top: 40px;
  background-color: rgba(38, 40, 53, 1);
  padding: 32px 64px 32px 64px;
  border-radius: 40px;
  border: 1px solid rgba(56, 65, 74, 1);
  justify-content: center;
}
.profile-container .work-detail-grid {
  padding: 0 30px;
  border-right: 1px solid;
  border-image: linear-gradient(
    rgba(56, 65, 74, 0) 0%,
    #38414a 49.83%,
    rgba(56, 65, 74, 0) 100%
  );
  border-image-slice: 1;
  border-radius: 1px;
}
.profile-container .work-detail-grid:last-child {
  border-right: none;
}
.profile-button {
  margin-top: 30px;
  gap: 35px;
  display: flex;
  flex-wrap: wrap;
}
.profile-container .profile-text {
  color: rgba(195, 255, 85, 1);
  font-weight: 800;
  font-size: var(--font-main-point-title);
  font-weight: var(--font-weight-400) !important;
  font-family: "Impact", sans-serif !important;
}
.profile-text2 {
  margin-top: 20px !important;
  font-weight: var(--font-weight-600) !important;
  font-size: var(--font-sub-title) !important;
}

.spin-text {
  margin-top: 15px;
  font-size: var(--font-main-title);
  font-family: "Impact", sans-serif !important;
  font-weight: var(--font-weight-400);
  z-index: 1;
  position: relative;
}
.spin-text2 {
  font-weight: var(--font-weight-400) !important;
  font-size: var(--font-size-base) !important;
  margin: 15px 140px;
  z-index: 1;
  position: relative;
}
.available-text {
  text-align: start;
  margin-top: 25px;
  font-size: var(--font-sub-title);
  font-weight: var(--font-weight-600);
}
.mission-detail {
  text-align: start;
}
.mission-section-detail {
  background-color: rgba(38, 40, 53, 1);
  padding: 24px 32px 24px 32px;
  border-radius: 40px;
  border: 1px solid rgba(56, 65, 74, 1);
  margin-bottom: 25px;
  transition: border-color 0.3s, transform 0.2s;
}
.mission-section {
  margin-top: 35px;
}
.available:hover {
  border: 1px solid #fff;
  /* transform: scale(1.01); */
}
.pts-item {
  justify-content: center;
  height: 100%;
  font-weight: var(--font-weight-400);
  font-family: "Impact", sans-serif !important;
  font-size: var(--font-main-title);
  display: flex;
  flex-direction: column;
}
.mission-text {
  font-weight: var(--font-weight-600);
  font-size: var(--font-sub-title);
  color: var(--light-green-color);
  margin-bottom: 10px;
}
.mission-text-p {
  font-weight: var(--font-weight-300) !important;
  font-size: var(--font-size-base) !important;
}

.user-profile {
  margin-top: -120px;
  text-align: center;
}
.user-profile img {
  width: 160px; /* Set width explicitly */
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
}
.profile-container .spinner-bg {
  position: relative;
}
.profile-container .spinner-bg::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  background-image: url("../../assets/daily_spin_left_bg.png");
  width: 220px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-container .spinner-bg::before {
  content: "";
  position: absolute;
  top: unset;
  bottom: 0;
  right: 0;
  background-image: url("../../assets/daily_spin_right_bg.png");
  width: 239px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 40px;
  height: 93%;
  background-repeat: no-repeat;
  background-size: cover;
}
.start-button-container {
  justify-content: end;
}

.start-button-container button {
  display: flex;
  align-items: center;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .spin-text2 {
    margin: 15px 85px;
  }
  .profile-container {
    padding: 32px 60px;
  }
  .profile-container .spinner-bg::after {
    width: 208px;
  }

  .profile-container .spinner-bg::before {
    width: 208px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .spin-text2 {
    margin: 15px 65px;
  }
  .profile-container {
    padding: 15px;
  }
  .profile-container .profile-detail {
    padding: 15px;
  }

  .user-profile {
    margin-top: -80px;
  }
  .user-profile img {
    width: 130px;
    height: 130px;
  }
  .profile-text2 {
    margin-bottom: 15px !important;
    margin-top: 13px !important;
    font-size: var(--font-small-content) !important;
  }

  .daily-spin-section {
    padding: 20px !important;
  }

  .mission-section-detail {
    padding: 24px;
  }
  .mission-detail {
    padding-bottom: 15px;
  }
  .pts-item {
    text-align: start;
  }
  .profile-container .spinner-bg::after {
    width: 189px;
  }

  .profile-container .spinner-bg::before {
    width: 189px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .spin-text2 {
    margin: 15px 0px;
  }
  .profile-container {
    padding: 15px;
  }
  .profile-container .profile-detail {
    padding: 15px;
  }

  .user-profile {
    margin-top: -75px;
  }
  .user-profile img {
    width: 120px;
    height: 120px;
  }
  .profile-text2 {
    margin-bottom: 15px !important;
    margin-top: 13px !important;
    font-size: var(--font-small-content) !important;
  }

  .daily-spin-section {
    padding: 20px !important;
  }

  .mission-section-detail {
    padding: 24px;
  }
  .mission-detail {
    padding-bottom: 15px;
  }
  .pts-item {
    text-align: start;
  }
}

@media (min-width: 575px) and (max-width: 767.5px) {
  .profile-container .spinner-bg::after {
    top: 0px;
    background-image: url("../../assets/daily_spin_left_mini_bg.png");
    width: 105px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 0px;
    height: 50%;
  }

  .profile-container .spinner-bg::before {
    top: unset;
    bottom: 0px;
    background-image: url("../../assets/daily_spin_right_mini_bg.png");
    width: 150px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 40px;
    height: 40%;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  /* Your CSS styles for xs breakpoint */
  .spin-text2 {
    margin: 15px 0px;
  }
  .profile-container {
    padding: 15px;
  }
  .profile-container .profile-detail {
    padding: 15px;
  }
  .user-profile {
    margin-top: -70px;
  }
  .user-profile img {
    width: 100px;
    height: 100px;
  }
  .profile-button {
    justify-content: center;
  }
  .profile-text2 {
    margin-bottom: 15px !important;
    margin-top: 13px !important;
    font-size: var(--font-small-content) !important;
  }

  .daily-spin-section {
    padding: 20px !important;
  }
  .profile-button-bpm {
    display: block;
  }
  .profile-button-bpm button {
    margin-right: 4px;
    padding: 14px 22px 12px 22px;
  }
  .mission-section-detail {
    padding: 15px;
  }
  .mission-detail {
    padding-bottom: 15px;
  }
  .start-button-container button {
    padding: 13px 20px 13px 21px;
  }
  .pts-item {
    text-align: start;
  }
  .profile-container .spinner-bg::after {
    top: 0px;
    background-image: url("../../assets/daily_spin_left_mini_bg.png");
    width: 90px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 0px;
    height: 115px;
  }

  .profile-container .spinner-bg::before {
    top: unset;
    background-image: url("../../assets/daily_spin_right_mini_bg.png");
    width: 130px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 40px;
    height: 32%;
    bottom: 0px;
  }
}

@media (max-width: 600px) {
  .profile-container .work-detail-grid {
    border-right: none;
    padding: 0px 15px;
  }
  .profile-container .work-detail-grid:not(:last-child) {
    border-bottom: 1px solid;
    margin-bottom: 15px;
    border-image: linear-gradient(
      -90deg,
      rgba(56, 65, 74, 0) 0%,
      #38414a 49.83%,
      rgba(56, 65, 74, 0) 100%
    );
    border-image-slice: 1;
  }
}
