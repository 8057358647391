.footer-container {
  margin-top: 80px;
  border-top: 1px solid var(--white-color);
  padding: 80px 0px;
  font-family: "Impact", sans-serif !important;
}
.footer-container .footer-title {
  text-transform: uppercase;
  font-size: var(--font-xxl-title);
  font-weight: var(--font-weight-400);
  display: flex;
  flex-wrap: wrap;
}

.list-item-avatar img {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
}

.footer-list-item {
  gap: 25px;
  padding-top: 0px !important;
}
.footer-container .MuiList-root {
  padding-top: 0px !important;
}
.footer-list-item span a {
  font-weight: var(--font-weight-600);
  font-size: var(--font-sub-title);
  color: var(--white-color);
  text-decoration: none;
  font-family: "Inter", sans-serif !important;
}

.footer-list-item p a {
  color: var(--light-green-color); /* Secondary text color */
  font-weight: var(--font-weight-600);
  font-size: var(--font-main-title);
  text-decoration: none;
  font-family: "Inter", sans-serif !important;
}

@media (max-width: 768px) {
  .footer-container {
    padding: 40px 0px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .footer-container {
    padding: 30px 0px;
  }

  .footer-list-item {
    padding: 0 0 10px 0 !important;
  }
}
