.auth-navbar {
  position: relative !important;
}
.auth-navbar .profile-page-logo {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 25px;
  margin-left: 70px;
}
.mission-profile-logo img {
  height: auto;
  width: 100%;
  max-width: 150px;
}
.bpm-profile-logo,
.mission-profile-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-line {
  border-right: 1px solid;
  border-image: linear-gradient(
    rgba(56, 65, 74, 0) 0%,
    #38414a 49.83%,
    rgba(56, 65, 74, 0) 100%
  );
  border-image-slice: 1;
  border-radius: 1px;
}
@media (max-width: 599px) {
  .auth-navbar .profile-page-logo {
    text-align: left;
    justify-content: left;
    gap: 15px;
    margin-left: 0px;
  }
  .mission-profile-logo img {
    max-width: 90px;
  }
  .bpm-profile-logo img {
    height: 40px;
  }
}

@media (max-width: 767.5px) {
  .auth-navbar .profile-page-logo {
    text-align: left;
    justify-content: left;
    gap: 15px;
    margin-left: 0px;
  }
}
