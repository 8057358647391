.continue-mission-button {
  justify-content: center;
  display: flex;
  margin: 25px 0px;
}
.input-referral {
  justify-content: center !important;
}
.welcome-img img {
  width: auto;
  height: auto;
}
.redeem-btn {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.input-button {
  justify-content: center !important;
}
@media (min-width: 1300px) and (max-width: 1499px) {
  .welcome-img img {
    width: 240px;
    height: auto;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .welcome-img img {
    width: 220px;
    height: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .welcome-img img {
    width: 200px;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .welcome-img img {
    width: 180px;
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .welcome-img img {
    width: 150px;
    height: auto;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .redeem-btn{
    display: block !important;
  }
  .input-button{
    margin-top: 15px;
  }
  .input-referral {
    display: block !important;
  }
  .welcome-img img {
    width: 120px;
    height: auto;
  }
}
