.faq-box-title {
  background-color: var(--background-color);
  border-radius: 15px;
  padding: 16px 20px;
  margin-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 575px) {
  .MuiDialog-paper {
    padding: 15px 0px !important;
  }
}
