.home-page {
  background: linear-gradient(0deg, #1c1d26 0%, rgba(28, 29, 38, 0) 75%);
  z-index: 1;
  position: relative;
}

.hero-section {
  margin-bottom: 80px;
  text-align: center;
  /* background: linear-gradient(0deg, #1c1d26 0%, rgba(28, 29, 38, 0) 75%);
    z-index: 1;
    position: relative; */
}
.hero-section-title {
  font-family: "Impact", sans-serif !important;
  font-size: var(--font-main-title);
  font-weight: var(--font-weight-400);
}
.hero-section-button {
  margin-top: 20px;
  gap: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
}
.home-page .hero-content {
  margin: 30px 0 !important;
  font-size: var(--font-large-content) !important;
  font-weight: var(--font-weight-400);
}

.hero-section-content {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.work-container {
  position: relative;
  z-index: 1;
  padding: 48px 75px 48px 75px;
  border: 2px solid #38414a;
  border-radius: 35px;
  background-color: var(--background-color);
  text-align: center;
}
.work-title {
  font-family: "Impact", sans-serif !important;
  font-weight: var(--font-weight-400);
  font-size: var(--font-main-title);
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.home-page .work-container .grid-item {
  margin: 15px 0px;
  text-align: center;
  font-weight: 600;
  font-size: var(--font-sub-title);
}

.home-page .work-container .inner-text {
  font-size: var(--font-size-base);
}

.work-container .work-detail-grid {
  padding: 0 75px;
  border-right: 1px solid;
  border-image: linear-gradient(
    rgba(56, 65, 74, 0) 0%,
    #38414a 49.83%,
    rgba(56, 65, 74, 0) 100%
  );
  border-image-slice: 1;
  border-radius: 1px;
}
.work-detail-grid:last-child {
  border-right: none;
}
.hero-section-logo {
  display: flex;
  justify-content: center;
  padding: 60px 0px;
  z-index: 1;
  position: relative;
}
.hero-section-logo img {
  height: auto;
  max-width: 350px;
  width: 100%;
}
.image-container {
  height: 200px;
  width: 200px;
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.video-overlay video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
video::-webkit-media-controls {
  display: none;
}
/* .hero-section-logo img {
  margin: 80px 0px;
} */
@media (min-width: 1300px) and (max-width: 1499px) {
  .work-container .work-detail-grid {
    padding: 0 56px;
  }
  .work-container {
    padding: 48px 40px 48px 40px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .work-container .work-detail-grid {
    padding: 0 20px;
  }
  .work-container {
    padding: 48px 40px 48px 40px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .work-container .work-detail-grid {
    padding: 0 20px;
  }
  .work-container {
    padding: 48px 40px 48px 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .work-container .work-detail-grid {
    padding: 0 30px;
  }
  .work-container {
    padding: 48px 40px 48px 40px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .work-container .work-detail-grid {
    padding: 0 30px;
  }
  .work-container {
    padding: 48px 40px 48px 40px;
  }
  .hero-section-logo img {
    max-width: 250px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .work-container .work-detail-grid {
    padding: 0 20px;
  }
  .work-container {
    padding: 30px 24px 30px 24px;
  }
  .hero-section-logo img {
    max-width: 200px;
  }
  .hero-section-button {
    flex-wrap: wrap;
  }
}

/* For xs (0px and up) */
@media (max-width: 599px) {
  /* Your CSS styles for xs breakpoint */
  .work-container .work-detail-grid {
    border-right: none;
    padding: 0px 20px;
  }
  .work-container {
    padding: 30px 24px 30px 24px;
  }
  .work-container .inner-text {
    margin-bottom: 35px;
  }

  .work-container .work-detail-grid:not(:last-child) {
    border-bottom: 1px solid;
    margin-bottom: 35px;
    border-image: linear-gradient(
      -90deg,
      rgba(56, 65, 74, 0) 0%,
      #38414a 49.83%,
      rgba(56, 65, 74, 0) 100%
    );
    border-image-slice: 1;
  }
}

/* For sm (600px and up) */
@media (min-width: 600px) {
  /* Your CSS styles for sm breakpoint */
  .work-container .work-detail-grid:not(:first-child) {
    border-right: none; /* Remove border from all elements except the first child */
  }

  .work-container .work-detail-grid:first-child {
    margin-bottom: 20px;
    border-right: 1px solid;
    border-image: linear-gradient(
      rgba(56, 65, 74, 0) 0%,
      #38414a 49.83%,
      rgba(56, 65, 74, 0) 100%
    );
    border-image-slice: 1;
    border-radius: 1px;
  }
}

/* For md (900px and up) */
@media (min-width: 900px) {
  /* Your CSS styles for md breakpoint */

  /* Override the previous rule */
  .work-container .work-detail-grid:not(:first-child) {
    border-right: 1px solid;
    border-image: linear-gradient(
      rgba(56, 65, 74, 0) 0%,
      #38414a 49.83%,
      rgba(56, 65, 74, 0) 100%
    );
    border-image-slice: 1;
    border-radius: 1px;
  }

  /* Adjust the last child style if needed */
  .work-detail-grid:last-child {
    border-right: none !important;
  }
}
