.common-bg {
  position: relative;
}

.common-bg::after {
  content: "";
  position: absolute;
  top: -90px;
  left: 0;
  background-image: url("../../assets/bg_left_green.png");
  width: 442px;
  height: 766px;
  background-repeat: no-repeat;
}

.common-bg::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  background-image: url("../../assets/bg_right_purple.png");
  width: 240px;
  height: 899px;
  background-repeat: no-repeat;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .common-bg::after {
    top: -90px;
    left: -130px;
  }

  .common-bg::before {
    top: 40px;
    right: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .common-bg::after {
    top: -90px;
    left: -230px;
  }

  .common-bg::before {
    top: 40px;
    right: -110px;
    right: 0px;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .common-bg::after {
    top: -90px;
    left: -290px;
  }

  .common-bg::before {
    top: -40px;
    right: -110px;
    right: 0px;
  }
}

@media (max-width: 575px) {
  .common-bg::after {
    content: "";
    position: absolute;
    top: -90px;
    left: 0;
    background-image: url("../../assets/bg_mb_left_green.png");
    width: 114px;
    height: 230px;
    background-repeat: no-repeat;
  }
  .common-bg::before {
    content: "";
    position: absolute;
    top: 180px;
    right: 0;
    background-image: url("../../assets/bg_mb_right_purple.png");
    width: 53px;
    height: 372px;
    background-repeat: no-repeat;
  }
}
