.wallet-section-detail {
  background-color: #1c1d26;
  border-radius: 15px;
  padding: 16px 20px;
  margin-bottom: 20px;
}
.wallet-text {
  font-size: var(--font-large-content);
  margin-bottom: 10px;
  font-weight: var(--font-weight-700);
}
.wallet-button {
  margin-top: 15px;
}
.wallet-section-detail .start-button-container {
  justify-content: center;
}
.wallet-details {
  border-top: 1px solid;
  border-image: linear-gradient(
    -90deg,
    rgba(56, 65, 74, 0) 0%,
    #38414a 49.83%,
    rgba(56, 65, 74, 0) 100%
  );
  padding: 25px 0px;
  margin-top: 25px;
  border-image-slice: 1;
  border-radius: 1px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .wallet-details {
    text-align: center;
  }
  .wallet-details .mission-detail {
    margin-top: 15px;
    text-align: center;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .wallet-details {
    text-align: center;
  }
  .wallet-details .mission-detail {
    margin-top: 15px;
    text-align: center;
  }
}
